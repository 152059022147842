export const texts = {
  error: "Fehler",
  warning: "Warnung",
  success: "Erfolgreich",
  emailVerifiedTitle: "Email bestätigt",
  emailVerified:
    "Sie haben Ihre Email erfolgreich bestätigt und können sich nun einloggen.",
  emailInUseTitle: "Email vergeben",
  emailInUse:
    "Diese E-Mail Adresse wird schon benutzt. Bitte loggen Sie sich ein.",
  successRegistrationTitle: "Erfolgreich registriert",
  successRegistration:
    "Bitte bestätigen Sie Ihre Email, um sich einloggen zu können.",
  unverifiedTitle: "Email aktivieren",
  unverified: "Das Konto ist nicht verfiziert.",
  wrongCredential:
    "Ihre Angaben sind nicht korrekt. \nBitte versuchen Sie es erneut.",
  deletedTitle: "Fehler",
  deletedUser:
    "Das Konto existiert nicht mehr. \nBitte versuchen Sie es erneut.",
  userNotFoundTitle: "Konto existiert nicht",
  userNotFound:
    "Ihr Konto wurde nicht gefunden. Wenn Sie Ihr Konto reaktivieren, aktivieren Sie bitte zunächst Ihr Konto über die Anmeldeseite.",
  passwordThrottled:
    " Bitte überprüfen Sie zuerst Ihre E-Mail oder warten Sie einen Moment und versuchen Sie es erneut!",
  sessionExpiredTitle: "Sitzung abgelaufen",
  sessionExpired:
    "Die Sitzung zum Zurücksetzen des Passwortes ist abgelaufen. Bitte fragen Sie einen neue Link an.",
  passwordChangedTitle: "Passwort geändert",
  passwordChanged: "Sie haben Ihr Passwort erfolgreich geändert",
  missingFieldsTitle: "Fehlerhafte Eingabe",
  missingFields: "Ihre Eingabe entspricht nicht den benötigten Werten.",
  wrongPasswordTitle: "Passwort falsch",
  wrongPassword: "Ihr Passwort stimmt nicht überein.",
  dataUpdated: "Die Daten wurden erfolgreich aktualisiert.",
  emailTaken: "Diese Email ist bereits vergeben.",
  successfulRegistration:
    "Ihre Registrierung war erfolgreich, bitte prüfen Sie Ihr Email-Postfach!",

  invalidToken: "Der Token-Code ist ungültig.",
  invalidCredential: "Benutzername oder Passwort falsch.",
  invalidPassword: "Das Passwort ist falsch.",
  notUpdated: "Änderung war nicht erfolgreich.",
  invalidBroker: "Der Link ist falsch oder abgelaufen.",
  overRequest: "Information ist nicht abrufbar.",
  tryAgain: "Warten Sie eine Minute und versuchen Sie es erneut.",
  notSentEmail: "Email nicht verschickt.",
  notFoundEmail: "Email nicht gefunden.",
  defaultMessage: "Bitte versuchen Sie es später erneut.",
  unAuthorized: "Nicht verifizierter User! Email oder Passwort sind falsch.",
};
