export const SITE_KEY = "6LeBh2ocAAAAAI83nUveIIR1m15ucuNX_8AllX3c";

export const loadRecaptcha = () => {
  const isScriptExist = document.getElementById("recaptcha-key");

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
    script.id = "recaptcha-key";
    script.onload = function () {
      //console.log("Script loaded!");
    };
    document.body.appendChild(script);
  }

  if (isScriptExist) {
    //console.log("Script loaded!");
  }
};

export const isEmpty = (obj) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
