import store from "../redux/store";
import actions from "../redux/actions";
import { texts } from "../data/texts";

export const setStoreMessage = (type, title, message) => {
  return store.dispatch(
    actions.message.addMessage({
      type: type,
      title: title,
      description: message,
    })
  );
};

export function responseMessages(code) {
  switch (code) {
    case 401:
      return {
        type: "error",
        title: texts["error"],
        message: texts["unAuthorized"],
      };
    case 302:
      return {
        type: "error",
        title: texts["error"],
        message: texts["linkNotFound"],
      };
    case 410:
      return {
        type: "warning",
        title: texts["unverifiedTitle"],
        message: texts["unverified"],
      };
    case 411:
      return {
        type: "error",
        title: texts["error"],
        message: texts["invalidToken"],
      };
    case 412:
      return {
        type: "error",
        title: texts["error"],
        message: texts["wrongCredential"],
      };
    case 413:
      return {
        type: "error",
        title: texts["error"],
        message: texts["wrongCredential"],
      };
    case 414:
      return {
        type: "error",
        title: texts["error"],
        message: texts["notUpdated"],
      };
    case 415:
      return {
        type: "error",
        title: texts["error"],
        message: texts["invalidBroker"],
      };
    case 416:
      return {
        type: "error",
        title: texts["error"],
        message: texts["overRequest"],
      };
    case 417:
      return {
        type: "error",
        title: texts["error"],
        message: texts["passwordThrottled"],
      };
    case 418:
      return {
        type: "error",
        title: texts["error"],
        message: texts["userNotFound"],
      };
    case 419:
      return {
        type: "error",
        title: texts["error"],
        message: texts["notSentEmail"],
      };
    case 420:
      return {
        type: "error",
        title: texts["sessionExpiredTitle"],
        message: texts["sessionExpired"],
      };
    case 421:
      return {
        type: "warning",
        title: texts["deletedTitle"],
        message: texts["deletedUser"],
      };
    case 422:
      return {
        type: "warning",
        title: texts["error"],
        message: texts["noRecordFound"],
      };
    default:
      return {
        type: "error",
        title: texts["error"],
        message: texts["defaultMessage"],
      };
  }
}
