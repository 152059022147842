import store from "../redux/store";
import { parseJwt } from "../helper";
import actions from "../redux/actions";
import { BASE_URL } from "./config";
import { responseMessages, setStoreMessage } from "../helper/responseHelper";
import { texts } from "../data/texts";

export const getToken = () => {
  return store.getState()?.user?.token;
};

export const getHeader = (token = false) => {
  if (token === true)
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    };
  else
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      Accept: "application/json",
    };
};

export const handleLogin = async (
  { email, password },
  token,
  successCallback,
  callback,
  checkStatus
) => {
  const resp = await fetch(`${BASE_URL}/thg/login`, {
    headers: getHeader(),
    method: "POST",
    body: JSON.stringify({ email, password }, token),
  });

  const response = await resp.json();
  if (response?.success === false) {
    const message = responseMessages(response?.error_code);
    setStoreMessage(message["type"], message["title"], message["message"]);
    if (callback) {
      callback();
    }
    if (response?.error_code === 421) {
      if (checkStatus) {
        checkStatus();
      }
    }
  } else if (response?.success === true) {
    const {
      token,
      first_name,
      last_name,
      email,
      company,
      tax_number,
      street,
      postal_code,
      city,
      account_holder,
      iban,
      is_company,
      unsubscribed_at,
      deleted_at,
    } = response.data;

    store.dispatch(
      actions.user.setUser({
        token,
        first_name,
        last_name,
        email,
        company,
        tax_number,
        street,
        postal_code,
        city,
        account_holder,
        iban,
      })
    );
    store.dispatch(
      actions.user.updateCompanyInfo({
        is_company,
      })
    );
    store.dispatch(
      actions.user.updateSubscription({
        unsubscribed_at,
        deleted_at,
      })
    );

    if (successCallback) {
      successCallback();
    }
  }
};

export const updateUserProfile = async (
  {
    first_name,
    last_name,
    company,
    tax_number,
    street,
    postal_code,
    city,
    password,
  },
  unauthorizedCallback
) => {
  const resp = await fetch(`${BASE_URL}/thg/me`, {
    method: "POST",
    headers: getHeader(true),
    body: JSON.stringify({
      first_name,
      last_name,
      company,
      tax_number,
      street,
      postal_code,
      city,
      password,
    }),
  });

  const response = await resp.json();
  if (response?.error_code === 401 || response?.errors) {
    setStoreMessage(
      "warning",
      texts["missingFieldsTitle"],
      texts["missingFields"]
    );
    store.dispatch(actions.user.resetUser());
    if (unauthorizedCallback) {
      unauthorizedCallback();
    }
    return null;
  } else if (response?.success === false && response?.error_code === 413) {
    setStoreMessage(
      "error",
      texts["wrongPasswordTitle"],
      texts["wrongPassword"]
    );
    return null;
  } else if (response.success === true) {
    const responseData = response.data;
    store.dispatch(actions.user.updateUserInfo(responseData));
    setStoreMessage("success", texts["success"], texts["dataUpdated"]);
    return true;
  }
};

export const updateAccountInfo = async (
  {
    account_holder,
    iban,
    has_iban,
    password,
    is_bank_details_confirmed = false,
  },
  unauthorizedCallback
) => {
  const resp = await fetch(`${BASE_URL}/thg/me`, {
    method: "PUT",
    headers: getHeader(true),
    body: JSON.stringify({
      account_holder,
      iban,
      has_iban,
      password,
      is_bank_details_confirmed,
    }),
  });

  const response = await resp.json();

  if (response.success === false) {
    setStoreMessage(
      "warning",
      texts["missingFieldsTitle"],
      texts["missingFields"]
    );
    store.dispatch(actions.user.resetUser());
    if (unauthorizedCallback) {
      unauthorizedCallback();
    }
    return null;
  } else if (response.errors) {
    setStoreMessage(
      "error",
      texts["wrongPasswordTitle"],
      texts["wrongPassword"]
    );
    return null;
  } else if (response.success === true) {
    const { account_holder, iban } = response.data;
    store.dispatch(
      actions.user.updateAccountInfo({
        account_holder,
        iban,
        is_bank_details_confirmed,
      })
    );
    setStoreMessage("success", texts["success"], texts["dataUpdated"]);
    return true;
  }
};

export const updateUserPassword = async (
  { current_password, password, password_confirmation },
  unauthorizedCallback
) => {
  const resp = await fetch(`${BASE_URL}/thg/me`, {
    method: "PATCH",
    headers: getHeader(true),
    body: JSON.stringify({ current_password, password, password_confirmation }),
  });

  const response = await resp.json();

  if (response.success === false) {
    setStoreMessage(
      "warning",
      texts["missingFieldsTitle"],
      texts["missingFields"]
    );

    store.dispatch(actions.user.resetUser());
    if (unauthorizedCallback) {
      unauthorizedCallback();
    }
    return null;
  } else if (response.errors) {
    setStoreMessage(
      "error",
      texts["wrongPasswordTitle"],
      texts["wrongPassword"]
    );
    return null;
  } else if (response.success === true) {
    setStoreMessage("success", texts["success"], texts["dataUpdated"]);

    return true;
  }
};

export const isLoggedIn = () => {
  const token = store.getState()?.user?.token;
  const decodedJwt = parseJwt(token);

  if (!decodedJwt?.exp) {
    return false;
  }

  if (decodedJwt.exp * 1000 < Date.now()) {
    store.dispatch(actions.user.resetUser());
    return false;
  }

  return true;
};

export const logout = async () => {
  const response = await fetch(`${BASE_URL}/thg/logout`, {
    method: "POST",
    headers: getHeader(true),
  });
  store.dispatch(actions.user.resetUser());
  store.dispatch(actions.message.removeMessage());

  response.json().then((json) => {
    //console.log(json);
  });
};

export const handleRegister = async (
  {
    first_name,
    last_name,
    email,
    company,
    tax_number,
    street,
    postal_code,
    city,
    password,
    password_confirmation,
    user_type,
    privacy,
    revocation,
    site_ref,
  },
  token,
  affiliateHash,
  emailAlreadyTakenCallback,
  callback
) => {
  const resp = await fetch(`${BASE_URL}/thg/register`, {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      company,
      tax_number,
      street,
      postal_code,
      city,
      password,
      password_confirmation,
      user_type,
      privacy,
      revocation,
      site_ref,
      token: "accepted",
      affiliateHash,
    }),
  });

  const response = await resp.json();

  if (response?.errors?.email) {
    if (emailAlreadyTakenCallback) {
      emailAlreadyTakenCallback();
    }
    return null;
  } else if (response?.success === true) {
    if (!response) {
      return false;
    }
    setStoreMessage(
      "success",
      texts["successRegistrationTitle"],
      texts["successRegistration"]
    );

    if (callback) {
      callback();
    }
    return true;
  }
};

export const forgotPassword = async ({ email }) => {
  const resp = await fetch(`${BASE_URL}/thg/forgot-password`, {
    headers: getHeader(),
    method: "POST",
    body: JSON.stringify({
      email,
    }),
  });
  return await resp.json();
};

export const resetPassword = async ({
  email,
  password,
  password_confirmation,
  token,
}) => {
  const resp = await fetch(`${BASE_URL}/thg/reset-password`, {
    headers: getHeader(),
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      password_confirmation,
      token,
    }),
  });
  return await resp.json();
};
